<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">產業類別管理</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <div v-show="!grid.isEditing && grid.isRowSelected" id="batch-dropdown" class="dropdown ml-auto mr-2 sm:ml-0">
          <button class="dropdown-toggle button px-2 box bg-blue-500 text-white flex items-center">
            <FontAwesome icon="tasks" type="fas" class="w-4 h-4 mr-1" />
            群組操作
          </button>
        </div>
        <button v-show="!grid.isEditing" class="button text-white bg-theme-1 shadow-md mr-2 flex items-center" @click="grid.addNewRow">
          <FontAwesome icon="plus" type="fas" class="w-4 h-4 mr-1" />
          新增
        </button>
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <div class="xl:flex sm:mr-auto">
          <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
            <input type="search" class="input w-full sm:w-40 xxl:w-full mt-2 sm:mt-0 border" v-model.trim="grid.keyword" placeholder="請輸入關鍵字" />
          </div>
          <div class="mt-2 xl:mt-0">
            <button type="button" class="button w-full sm:w-16 bg-theme-1 text-white" @click="grid.refresh">
              搜尋
            </button>
          </div>
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden mt-2">
        <grid
          ref="grid"
          v-bind="gridOptions"
          @addNewRow="(row, callback) => { row.Published = false; row.Ordinal = 0; callback(); }"
          @removeSelectedRows="hideBatchDropDown()"
        >
          <template #modal="{ row, submit, reset }">
            <vxe-form
              ref="form"
              v-bind="formOptions"
              :data="row"
              @reset="reset"
              @submit="submit"
            >
              <template #column-parent-id="{ data }">
                <select-box
                  :transfer="true"
                  v-model="data.ParentId"
                  v-bind="parentIdSelectOptions"
                ></select-box>
              </template>
            </vxe-form>
          </template>
          <template #modal-footer>
            <vxe-button type="submit" status="primary" content="確認" @click="$refs.form.validate((errMap) => { if (errMap === undefined) $refs.form.dispatchEvent('submit'); })"></vxe-button>
            <vxe-button type="reset" content="重置" @click="$refs.form.dispatchEvent('reset')"></vxe-button>
          </template>
          <template #filter-name="{ column, $panel }">
            <div v-for="(option, index) in column.filters" :key="index" class="flex item-center justify-center">
              <input type="checkbox"
                class="input input--switch border mt-4 ml-2"
                :checked="option.checked"
                @input="grid.changeFilterEvent(event, option, $panel, true)"
              />
              <div class="relative m-2">
                <div class="absolute rounded-l-md w-10 h-full flex items-center justify-center bg-gray-100 dark:bg-dark-1 dark:border-dark-4 border text-gray-600">
                  {{option.label}}
                </div>
                <input
                  v-model.trim="option.data"
                  :disabled="!option.checked"
                  type="text"
                  class="input pl-12 w-full border col-span-4"
                  placeholder="請輸入文字"
                  @input="grid.changeFilterEvent(event, option, $panel)"
                />
              </div>
            </div>
          </template>
        </grid>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<script lang="ts">
import CloudFun, { Condition, defineComponent, Operator, ref } from '@cloudfun/core'
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'
import { VxeFormProps } from 'vxe-table'
import SelectBox, { SelectBoxOptions } from '@/cloudfun/components/SelectBox.vue'

export default defineComponent({
  components: {
    Grid,
    SelectBox
  },
  setup () {
    const model = CloudFun.current?.model
    const grid = ref<any>({})
    var printColumns = [
      { field: 'Name' },
      { field: 'Description' }
    ]

    const gridOptions: GridOptions = {
      stripe: false,
      title: '產業類別',
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      multiselect: false,
      columns: [{
        field: 'Name',
        title: '名稱',
        showHeaderOverflow: true,
        showOverflow: true,
        sortable: true,
        treeNode: true,
        editRender: { name: '$input', immediate: true, attrs: { type: 'text' } },
        filters: [{ checked: false, label: '包含' }],
        slots: { filter: 'filter-name' }
      },
      {
        field: 'FullName',
        title: '全名',
        showHeaderOverflow: true,
        showOverflow: true,
        sortable: true,
        treeNode: true,
        editRender: { name: '$input', immediate: true, attrs: { type: 'text' } },
        filters: [{ checked: false, label: '包含' }],
        slots: { filter: 'filter-name' }
      },
      {
        field: 'TopicType',
        title: '主題',
        showHeaderOverflow: true,
        showOverflow: true,
        sortable: true,
        formatter: ({ cellValue }) => {
          const item = model?.enums && Object.values(model?.enums.TopicType).find((e) => e.Value === cellValue);
          return item ? item.Name : "";
        }
      },
      { field: 'Published', title: '已發布', showHeaderOverflow: true, showOverflow: true, sortable: true, align: 'center', formatter: ({ cellValue }) => cellValue ? '是' : '否' },
      { field: 'Ordinal', title: '排序', showHeaderOverflow: true, showOverflow: true, sortable: true, align: 'center' }
      ],
      promises: {
        query: model ? (params) => {
          if (params.condition) params.condition.and('ParentId', Operator.IsNull)
          else params.condition = new Condition('ParentId', Operator.IsNull)
          return model.dispatch('program/query', params)
        } : undefined,
        queryAll: model ? () => model.dispatch('program/query') : undefined,
        save: model ? (params) => model.dispatch('program/save', params) : undefined
      },
      modalConfig: { showFooter: true, width: '70%' },
      treeConfig: { children: 'Children' }
    }

    const formOptions: VxeFormProps = {
      titleWidth: 80,
      titleAlign: 'right',
      items: [
        {
          field: 'Name',
          title: '名稱',
          span: 24,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        }, {
          field: 'FullName',
          title: '全名',
          span: 24,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        }, {
          field: 'TopicType',
          title: '主題',
          span: 12,
          itemRender: { name: "$select", options: model ? Object.values(model.enums.TopicType).map(e => { return { label: e.Name, value: e.Value } }) : [] }
        }, {
          field: 'Published',
          title: '已發布',
          span: 12,
          // titleWidth: 60,
          itemRender: { name: '$select', options: [{ value: false, label: '否' }, { value: true, label: '是' }] }
        }, {
          field: 'Ordinal',
          title: '排序',
          span: 12,
          // titleWidth: 60,
          itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字' } }
        }, {
          field: 'ParentId',
          title: '父類別',
          span: 24,
          // titleWidth: 60,
          slots: { default: 'column-parent-id' }
        },
      ],
      rules: {
        Name: [{ type: 'string', required: true }],
        TopicType: [{ required: true }]
      }
    }

    const parentIdSelectOptions: SelectBoxOptions = {
      showSearch: true,
      rowId: 'Id',
      placeholder: '選擇分類',
      textField: 'Name',
      valueField: 'Id',
      formatText: async (row: any) => {
        let entity = await model!.dispatch("program/find", row.Id); // eslint-disable-line
        let text = entity.Name
        while (entity.Parent) {
          entity = entity.Parent
          text = `${entity.Name}>${text}`
        }
        return text
      },
      columns: [
        { field: 'Name', title: '名稱', showHeaderOverflow: true, showOverflow: true, sortable: true, treeNode: true }
      ],
      promises: {
        find: (value) => model!.dispatch("program/find", value), // eslint-disable-line
        query: (params) => {
          if (params.condition) params.condition.and('ParentId', Operator.IsNull)
          else params.condition = new Condition('ParentId', Operator.IsNull)
          return model!.dispatch("program/query", params); // eslint-disable-line
        }
      },
      treeConfig: { parent: 'ParentId', children: 'Children' }
    }

    return {
      grid,
      gridOptions,
      formOptions,
      parentIdSelectOptions
    }
  },
  methods: {
    hideBatchDropDown () {
      cash('#batch-dropdown').dropdown('hide')
    }
  }
})
</script>
